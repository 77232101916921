import { withServiceWorkerUpdater } from "@3m1/service-worker-updater";

const Updater = (props) => {
	const { newServiceWorkerDetected, onLoadNewServiceWorkerAccept } = props;

	if (newServiceWorkerDetected) {
		onLoadNewServiceWorkerAccept();
	}

	return;
};

export default withServiceWorkerUpdater(Updater);
