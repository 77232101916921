import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function CheckForUpdate() {
	const location = useLocation();
	const urlLocation = location.pathname;

	useEffect(() => {
		navigator.serviceWorker.getRegistrations().then((registrationsArray) => {
			registrationsArray[0]?.update();
		});
	}, [urlLocation]);
	return <div></div>;
}
