import React, { useState } from "react";
import styled from "styled-components";
import Routes from "../../routes/Routes";
import Flags from "./Flags";
import Logo from "./Logo";
import Nav from "./Nav";
import Social from "./Social";
import { motion } from "framer-motion";
import { Menu } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

const MainDiv = styled.div`
	height: 128px;
	-webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
	-moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
	box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
	display: flex;
	position: ${(props) => (props.location === "/online" ? `relative` : `fixed`)};
	width: 100%;
	top: 0;

	@media (max-width: 640px) {
		height: 100px;
	}
`;

function TobBar({ changeLanguage, t, isUnder1024px, isUnder640px }) {
	const [isOpen, setIsOpen] = useState(false);
	const location = useLocation();

	console.log(location.pathname);

	const variants = {
		open: { opacity: 1, y: "235px" },
		closed: { opacity: 1, y: "20px" },
	};

	const variants2 = {
		open: { opacity: 1, y: "-250px" },
		closed: { opacity: 1, y: "-30px" },
	};

	return (
		<>
			<MainDiv
				location={location.pathname}
				className="px-4 relative topBarDiv z-10"
			>
				<div className="container mx-auto flex relative">
					<div className="absolute flagsDiv">
						<Flags changeLanguage={changeLanguage} />
					</div>
					<div className="flex items-center justify-between w-full">
						<div className="flex items-center xl:gap-12 sm:gap-6 gap-0">
							<Logo />
							{isUnder640px === false && <Social t={t} />}
							{isUnder640px === true && (
								<a href={t("link.telefon")} className="flex items-center gap-5">
									<span className="phoneTopBarSpan text-orange">
										(68) 320 20 12
									</span>
								</a>
							)}
						</div>
						{isUnder1024px === true ? (
							<Menu
								onClick={() => setIsOpen((isOpen) => !isOpen)}
								className="menu"
							/>
						) : (
							<Nav t={t} setIsOpen={setIsOpen} />
						)}
					</div>
				</div>
			</MainDiv>
			{isUnder1024px === true && (
				<motion.div
					animate={isOpen ? "open" : "closed"}
					variants={location.pathname === "/online" ? variants2 : variants}
					className={
						location.pathname === "/online"
							? "absolute mobileNav online"
							: "absolute mobileNav"
					}
				>
					<div className="px-4 container mx-auto">
						<ul className="flex flex-col gap-4">
							<Routes t={t} setIsOpen={setIsOpen} />
						</ul>
					</div>
				</motion.div>
			)}
		</>
	);
}

export default TobBar;
