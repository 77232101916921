import { tabUnstyledClasses } from "@mui/base";
import { CleaningServicesTwoTone } from "@mui/icons-material";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

const MainDiv = styled.div`
	margin-top: 200px;
	overflow: hidden;

	@media (max-width: 640px) {
		margin-top: 100px;
	}
`;

const ContentDiv = styled.div`
	margin-top: ${(props) => (props.pizza === true ? `140px` : `50px`)};
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	@media (max-width: 1280px) {
		margin-top: ${(props) => (props.pizza === true ? `60px` : `40px`)};
	}
	@media (max-width: 1024px) {
		flex-direction: column;
	}
	@media (max-width: 640px) {
		margin-top: 40px;
		gap: 25px;
	}
`;

const Title = styled.span`
	font-size: 30px;
	font-weight: 800;

	@media (max-width: 1280px) {
		font-size: 25px;
	}
`;
const Desc = styled.span`
	font-size: 25px;
	font-weight: 200;

	@media (max-width: 1280px) {
		font-size: 20px;
	}
`;

const PriceDiv = styled.div`
	width: 100%;

	@media (min-width: 640px) {
		background-image: repeating-linear-gradient(
				0deg,
				#707070,
				#707070 10px,
				transparent 10px,
				transparent 14px,
				#707070 14px
			),
			repeating-linear-gradient(
				90deg,
				#707070,
				#707070 10px,
				transparent 10px,
				transparent 14px,
				#707070 14px
			),
			repeating-linear-gradient(
				180deg,
				#707070,
				#707070 10px,
				transparent 10px,
				transparent 14px,
				#707070 14px
			),
			repeating-linear-gradient(
				270deg,
				#707070,
				#707070 10px,
				transparent 10px,
				transparent 14px,
				#707070 14px
			);
		background-size: 1px 0%, 0% 1px, 1px 0%, 100% 1px;
		background-position: 0 0, 0 0, 100% 0, 0 100%;
		background-repeat: no-repeat;
	}
`;
const Price = styled.span`
	font-size: 25px;
	font-weight: 600;

	@media (max-width: 1280px) {
		font-size: 20px;
	}
`;
const SizeDiv = styled.div`
	position: absolute;
	top: -80px;
	right: 0;

	@media (max-width: 1280px) {
		top: -60px;
	}

	@media (max-width: 640px) {
		display: none;
	}
`;
const SizeTitle = styled.span`
	font-size: 20px;
	font-weight: 700;

	@media (max-width: 1280px) {
		font-size: 15px;
	}
`;
const SizeDesc = styled.span`
	font-size: 20px;
	font-weight: 200;

	@media (max-width: 1280px) {
		font-size: 15px;
	}
`;

const CategoryTitle = styled.span`
	font-size: 50px;
	font-weight: 650;

	@media (max-width: 1280px) {
		font-size: 30px;
	}
`;
const MainBoxDiv = styled.div`
	margin-top: 100px;

	@media (max-width: 1280px) {
		margin-top: 70px;
	}
`;
const RowContainerDiv = styled.div`
	width: 47%;

	@media (max-width: 1024px) {
		width: 100%;
	}

	@media (max-width: 640px) {
		padding-bottom: 15px;
		background-image: repeating-linear-gradient(
				0deg,
				#707070,
				#707070 10px,
				transparent 10px,
				transparent 14px,
				#707070 14px
			),
			repeating-linear-gradient(
				90deg,
				#707070,
				#707070 10px,
				transparent 10px,
				transparent 14px,
				#707070 14px
			),
			repeating-linear-gradient(
				180deg,
				#707070,
				#707070 10px,
				transparent 10px,
				transparent 14px,
				#707070 14px
			),
			repeating-linear-gradient(
				270deg,
				#707070,
				#707070 10px,
				transparent 10px,
				transparent 14px,
				#707070 14px
			);
		background-size: 1px 0%, 0% 1px, 1px 0%, 100% 1px;
		background-position: 0 0, 0 0, 100% 0, 0 100%;
		background-repeat: no-repeat;
	}
`;

function Menu({ t }) {
	const pizzaList = t(`menu.pizza`, { returnObjects: true });
	const zupaList = t(`menu.zupa`, { returnObjects: true });
	const sałatkaList = t(`menu.sałatka`, { returnObjects: true });
	const makaronyList = t(`menu.makarony`, { returnObjects: true });
	const zapiekankiList = t(`menu.zapiekanki`, { returnObjects: true });
	const kurczakList = t(`menu.kurczak`, { returnObjects: true });
	const wieprzowinaList = t(`menu.wieprzowina`, { returnObjects: true });
	const rybaList = t(`menu.ryba`, { returnObjects: true });
	const dzieciList = t(`menu.dzieci`, { returnObjects: true });
	const deseryList = t(`menu.desery`, { returnObjects: true });
	const dodatkiList = t(`menu.dodatki`, { returnObjects: true });
	const gorąceList = t(`menu.gorące`, { returnObjects: true });
	const piwoList = t(`menu.piwo`, { returnObjects: true });

	const napojeList = t(`menu.napoje`, { returnObjects: true });

	return (
		<div className="container mx-auto">
			<Helmet>
				<title>Restauracja La Gondola – MENU</title>
				<meta
					name="description"
					content="Już od 2016 roku specjalizujemy się w wypiekaniu pysznej chrupiącej pizzy. Jesteśmy doskonałym miejscem, żeby wybrać się z rodziną na obiad, spotkać się ze znajomymi czy zorganizować imprezę okolicznościową np. chrzciny lub komunię. Na miejscu czekać na Ciebie będzie przemiła i pomocna obsługa. Pizza, sałatki, makarony, dania obiadowe dostawa Zielona Góra"
				/>
				<link rel="canonical" href="/menu" />
			</Helmet>
			<MainDiv className="px-4 mb-12">
				<span className="bigText">{t("menu.title")}</span>
				<MainBoxDiv>
					{pizzaList.map((item1) => (
						<div className="sm:px-10 px-0 mt-12">
							<div>
								<CategoryTitle className="text-mediumColor">
									{item1.title}
								</CategoryTitle>
							</div>
							<ContentDiv pizza={true}>
								{item1.list.map((item2, index) => (
									<RowContainerDiv className="flex flex-col sm:mb-8 mb-0">
										<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start relative">
											{index === 0 && (
												<SizeDiv className="flex xl:gap-6 gap-7">
													<div className="flex flex-col items-center">
														<SizeTitle>{t("home.menu.size1")}</SizeTitle>
														<SizeDesc>(31cm)</SizeDesc>
													</div>
													<div className="flex flex-col items-center">
														<SizeTitle>{t("home.menu.size2")}</SizeTitle>
														<SizeDesc>(50cm)</SizeDesc>
													</div>
												</SizeDiv>
											)}
											<PriceDiv>
												<div className="flex flex-col sm:flex-row sm:gap-8 gap-0 sm:justify-between justify-start sm:items-center items-start">
													<div>
														<Title className="text-mediumColor">
															{item2.title}
														</Title>
													</div>
													<div className="gap-8 flex">
														<Price className="text-mediumColor">
															{item2.price1}
														</Price>
														<Price className="text-mediumColor">
															{item2.price2}
														</Price>
													</div>
												</div>
											</PriceDiv>
										</div>
										<div>
											<Desc className="text-mediumColor">{item2.desc}</Desc>
										</div>
									</RowContainerDiv>
								))}
							</ContentDiv>
						</div>
					))}
					{zupaList.map((item1) => (
						<div className="sm:px-10 px-0 mt-12">
							<div>
								<CategoryTitle className="text-mediumColor">
									{item1.title}
								</CategoryTitle>
							</div>
							<ContentDiv>
								{item1.list.map((item2, index) => (
									<RowContainerDiv className="flex flex-col sm:mb-8 mb-0">
										<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start relative">
											<PriceDiv>
												<div className="flex flex-col sm:flex-row sm:gap-8 gap-0 sm:justify-between justify-start sm:items-center items-start">
													<div>
														<Title className="text-mediumColor">
															{item2.title}
														</Title>
													</div>
													<div className="gap-8 flex">
														<Price className="text-mediumColor">
															{item2.price}
														</Price>
													</div>
												</div>
											</PriceDiv>
										</div>
										<div>
											<Desc className="text-mediumColor">{item2.desc}</Desc>
										</div>
									</RowContainerDiv>
								))}
							</ContentDiv>
						</div>
					))}
					{sałatkaList.map((item1) => (
						<div className="sm:px-10 px-0 mt-12">
							<div>
								<CategoryTitle className="text-mediumColor">
									{item1.title}
								</CategoryTitle>
							</div>
							<ContentDiv>
								{item1.list.map((item2, index) => (
									<RowContainerDiv className="flex flex-col sm:mb-8 mb-0">
										<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start relative">
											<PriceDiv>
												<div className="flex flex-col sm:flex-row sm:gap-8 gap-0 sm:justify-between justify-start sm:items-center items-start">
													<div>
														<Title className="text-mediumColor">
															{item2.title}
														</Title>
													</div>
													<div className="gap-8 flex">
														<Price className="text-mediumColor">
															{item2.price}
														</Price>
													</div>
												</div>
											</PriceDiv>
										</div>
										<div>
											<Desc className="text-mediumColor">{item2.desc}</Desc>
										</div>
									</RowContainerDiv>
								))}
							</ContentDiv>
						</div>
					))}
					{makaronyList.map((item1) => (
						<div className="sm:px-10 px-0 mt-12">
							<div>
								<CategoryTitle className="text-mediumColor">
									{item1.title}
								</CategoryTitle>
							</div>
							<ContentDiv>
								{item1.list.map((item2, index) => (
									<RowContainerDiv className="flex flex-col sm:mb-8 mb-0">
										<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start relative">
											<PriceDiv>
												<div className="flex flex-col sm:flex-row sm:gap-8 gap-0 sm:justify-between justify-start sm:items-center items-start">
													<div>
														<Title className="text-mediumColor">
															{item2.title}
														</Title>
													</div>
													<div className="gap-8 flex">
														<Price className="text-mediumColor">
															{item2.price}
														</Price>
													</div>
												</div>
											</PriceDiv>
										</div>
										<div>
											<Desc className="text-mediumColor">{item2.desc}</Desc>
										</div>
									</RowContainerDiv>
								))}
							</ContentDiv>
						</div>
					))}
					{zapiekankiList.map((item1) => (
						<div className="sm:px-10 px-0 mt-12">
							<div>
								<CategoryTitle className="text-mediumColor">
									{item1.title}
								</CategoryTitle>
							</div>
							<ContentDiv>
								{item1.list.map((item2, index) => (
									<RowContainerDiv className="flex flex-col sm:mb-8 mb-0">
										<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start relative">
											<PriceDiv>
												<div className="flex flex-col sm:flex-row sm:gap-8 gap-0 sm:justify-between justify-start sm:items-center items-start">
													<div>
														<Title className="text-mediumColor">
															{item2.title}
														</Title>
													</div>
													<div className="gap-8 flex">
														<Price className="text-mediumColor">
															{item2.price}
														</Price>
													</div>
												</div>
											</PriceDiv>
										</div>
										<div>
											<Desc className="text-mediumColor">{item2.desc}</Desc>
										</div>
									</RowContainerDiv>
								))}
							</ContentDiv>
						</div>
					))}
					{kurczakList.map((item1) => (
						<div className="sm:px-10 px-0 mt-12">
							<div>
								<CategoryTitle className="text-mediumColor">
									{item1.title}
								</CategoryTitle>
							</div>
							<ContentDiv>
								{item1.list.map((item2, index) => (
									<RowContainerDiv className="flex flex-col sm:mb-8 mb-0">
										<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start relative">
											<PriceDiv>
												<div className="flex flex-col sm:flex-row sm:gap-8 gap-0 sm:justify-between justify-start sm:items-center items-start">
													<div>
														<Title className="text-mediumColor">
															{item2.title}
														</Title>
													</div>
													<div className="gap-8 flex">
														<Price className="text-mediumColor">
															{item2.price}
														</Price>
													</div>
												</div>
											</PriceDiv>
										</div>
										<div>
											<Desc className="text-mediumColor">{item2.desc}</Desc>
										</div>
									</RowContainerDiv>
								))}
							</ContentDiv>
						</div>
					))}
					{wieprzowinaList.map((item1) => (
						<div className="sm:px-10 px-0 mt-12">
							<div>
								<CategoryTitle className="text-mediumColor">
									{item1.title}
								</CategoryTitle>
							</div>
							<ContentDiv>
								{item1.list.map((item2, index) => (
									<RowContainerDiv className="flex flex-col sm:mb-8 mb-0">
										<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start relative">
											<PriceDiv>
												<div className="flex flex-col sm:flex-row sm:gap-8 gap-0 sm:justify-between justify-start sm:items-center items-start">
													<div>
														<Title className="text-mediumColor">
															{item2.title}
														</Title>
													</div>
													<div className="gap-8 flex">
														<Price className="text-mediumColor">
															{item2.price}
														</Price>
													</div>
												</div>
											</PriceDiv>
										</div>
										<div>
											<Desc className="text-mediumColor">{item2.desc}</Desc>
										</div>
									</RowContainerDiv>
								))}
							</ContentDiv>
						</div>
					))}
					{rybaList.map((item1) => (
						<div className="sm:px-10 px-0 mt-12">
							<div>
								<CategoryTitle className="text-mediumColor">
									{item1.title}
								</CategoryTitle>
							</div>
							<ContentDiv>
								{item1.list.map((item2, index) => (
									<RowContainerDiv className="flex flex-col sm:mb-8 mb-0">
										<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start relative">
											<PriceDiv>
												<div className="flex flex-col sm:flex-row sm:gap-8 gap-0 sm:justify-between justify-start sm:items-center items-start">
													<div>
														<Title className="text-mediumColor">
															{item2.title}
														</Title>
													</div>
													<div className="gap-8 flex">
														<Price className="text-mediumColor">
															{item2.price}
														</Price>
													</div>
												</div>
											</PriceDiv>
										</div>
										<div>
											<Desc className="text-mediumColor">{item2.desc}</Desc>
										</div>
									</RowContainerDiv>
								))}
							</ContentDiv>
						</div>
					))}
					{dzieciList.map((item1) => (
						<div className="sm:px-10 px-0 mt-12">
							<div>
								<CategoryTitle className="text-mediumColor">
									{item1.title}
								</CategoryTitle>
							</div>
							<ContentDiv>
								{item1.list.map((item2, index) => (
									<RowContainerDiv className="flex flex-col sm:mb-8 mb-0">
										<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start relative">
											<PriceDiv>
												<div className="flex flex-col sm:flex-row sm:gap-8 gap-0 sm:justify-between justify-start sm:items-center items-start">
													<div>
														<Title className="text-mediumColor">
															{item2.title}
														</Title>
													</div>
													<div className="gap-8 flex">
														<Price className="text-mediumColor">
															{item2.price}
														</Price>
													</div>
												</div>
											</PriceDiv>
										</div>
										<div>
											<Desc className="text-mediumColor">{item2.desc}</Desc>
										</div>
									</RowContainerDiv>
								))}
							</ContentDiv>
						</div>
					))}
					{deseryList.map((item1) => (
						<div className="sm:px-10 px-0 mt-12">
							<div>
								<CategoryTitle className="text-mediumColor">
									{item1.title}
								</CategoryTitle>
							</div>
							<ContentDiv>
								{item1.list.map((item2, index) => (
									<RowContainerDiv className="flex flex-col sm:mb-8 mb-0">
										<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start relative">
											<PriceDiv>
												<div className="flex flex-col sm:flex-row sm:gap-8 gap-0 sm:justify-between justify-start sm:items-center items-start">
													<div>
														<Title className="text-mediumColor">
															{item2.title}
														</Title>
													</div>
													<div className="gap-8 flex">
														<Price className="text-mediumColor">
															{item2.price}
														</Price>
													</div>
												</div>
											</PriceDiv>
										</div>
										<div>
											<Desc className="text-mediumColor">{item2.desc}</Desc>
										</div>
									</RowContainerDiv>
								))}
							</ContentDiv>
						</div>
					))}
					{dodatkiList.map((item1) => (
						<div className="sm:px-10 px-0 mt-12">
							<div>
								<CategoryTitle className="text-mediumColor">
									{item1.title}
								</CategoryTitle>
							</div>
							<ContentDiv>
								{item1.list.map((item2, index) => (
									<RowContainerDiv className="flex flex-col sm:mb-8 mb-0">
										<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start relative">
											<PriceDiv>
												<div className="flex flex-col sm:flex-row sm:gap-8 gap-0 sm:justify-between justify-start sm:items-center items-start">
													<div>
														<Title className="text-mediumColor">
															{item2.title}
														</Title>
													</div>
													<div className="gap-8 flex">
														<Price className="text-mediumColor">
															{item2.price}
														</Price>
													</div>
												</div>
											</PriceDiv>
										</div>
										<div>
											<Desc className="text-mediumColor">{item2.desc}</Desc>
										</div>
									</RowContainerDiv>
								))}
							</ContentDiv>
						</div>
					))}
					{gorąceList.map((item1) => (
						<div className="sm:px-10 px-0 mt-12">
							<div>
								<CategoryTitle className="text-mediumColor">
									{item1.title}
								</CategoryTitle>
							</div>
							<ContentDiv>
								{item1.list.map((item2, index) => (
									<RowContainerDiv className="flex flex-col sm:mb-8 mb-0">
										<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start relative">
											<PriceDiv>
												<div className="flex flex-col sm:flex-row sm:gap-8 gap-0 sm:justify-between justify-start sm:items-center items-start">
													<div>
														<Title className="text-mediumColor">
															{item2.title}
														</Title>
													</div>
													<div className="gap-8 flex">
														<Price className="text-mediumColor">
															{item2.price}
														</Price>
													</div>
												</div>
											</PriceDiv>
										</div>
										<div>
											<Desc className="text-mediumColor">{item2.desc}</Desc>
										</div>
									</RowContainerDiv>
								))}
							</ContentDiv>
						</div>
					))}
					{piwoList.map((item1) => (
						<div className="sm:px-10 px-0 mt-12">
							<div>
								<CategoryTitle className="text-mediumColor">
									{item1.title}
								</CategoryTitle>
							</div>
							<ContentDiv>
								{item1.list.map((item2, index) => (
									<RowContainerDiv className="flex flex-col sm:mb-8 mb-0">
										<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start relative">
											<PriceDiv>
												<div className="flex flex-col sm:flex-row sm:gap-8 gap-0 sm:justify-between justify-start sm:items-center items-start">
													<div>
														<Title className="text-mediumColor">
															{item2.title}
														</Title>
													</div>
													<div className="gap-8 flex">
														<Price className="text-mediumColor">
															{item2.price}
														</Price>
													</div>
												</div>
											</PriceDiv>
										</div>
										<div>
											<Desc className="text-mediumColor">{item2.desc}</Desc>
										</div>
									</RowContainerDiv>
								))}
							</ContentDiv>
						</div>
					))}
					{napojeList.map((item1) => (
						<div className="sm:px-10 px-0 mt-12">
							<div>
								<CategoryTitle className="text-mediumColor">
									{item1.title}
								</CategoryTitle>
							</div>
							<ContentDiv>
								{item1.list.map((item2, index) => (
									<RowContainerDiv className="flex flex-col sm:mb-8 mb-0">
										<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start relative">
											<PriceDiv>
												<div className="flex flex-col sm:flex-row sm:gap-8 gap-0 sm:justify-between justify-start sm:items-center items-start">
													<div>
														<Title className="text-mediumColor">
															{item2.title}
														</Title>
													</div>
													<div className="gap-8 flex">
														<Price className="text-mediumColor">
															{item2.price}
														</Price>
													</div>
												</div>
											</PriceDiv>
										</div>
										<div>
											<Desc className="text-mediumColor">{item2.desc}</Desc>
										</div>
									</RowContainerDiv>
								))}
							</ContentDiv>
						</div>
					))}
				</MainBoxDiv>
			</MainDiv>
		</div>
	);
}

export default Menu;
