import { motion, useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Pasta from "../../img/pasta.png";
import Tunczyk from "../../img/tunczyk.png";
import Warkocz from "../../img/warkocz.png";

const MainDiv = styled.div`
	margin-top: 70px;
`;

const ContentDiv = styled(motion.div)`
	margin-top: 120px;
	/* max-width: 450px; */
	@media (max-width: 1280px) {
		margin-top: 60px;
	}
`;

const BlockDiv = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 370px;
`;

const Box = styled.div`
	background-color: #fdf9e7;
	border-radius: 50px;
	padding: 130px 20px;
`;

const Circle = styled.div`
	background-color: white;
	top: 15px;
	right: 15px;
	border-radius: 50px;
	padding: 30px 15px;

	@media (max-width: 1280px) {
		padding: 25px 15px;
	}
`;
const CircleText = styled.span`
	font-size: 25px;
	font-weight: 600;

	@media (max-width: 1280px) {
		font-size: 20px;
	}
`;

const Title = styled.span`
	font-size: 30px;
	font-weight: 700;

	@media (max-width: 1280px) {
		font-size: 25px;
	}
`;
const Desc = styled.span`
	font-size: 25px;
	font-weight: 500;

	@media (max-width: 1280px) {
		font-size: 20px;
	}
`;

function Card({ t, isUnder1280px, isUnder768px }) {
	const { ref, inView } = useInView({
		threshold: 0.1,
	});
	const [show, setShow] = useState(false);
	const animation = useAnimation();

	useEffect(() => {
		if (show === false) {
			if (inView) {
				animation.start({
					opacity: 1,
					transition: {
						type: "spring",
						duration: 1.5,
						bounce: 0.2,
						delay: 0.2,
					},
				});
				setShow(true);
			}
			if (!inView) {
				animation.start({ opacity: 0 });
			}
		}
	}, [inView]);

	return (
		<MainDiv className="px-4">
			<span className="bigText">{t("home.card.title")}</span>
			<div ref={ref}>
				<ContentDiv animate={animation} className="flex justify-between px-10">
					<Swiper
						modules={[Pagination]}
						spaceBetween={50}
						slidesPerView={
							isUnder1280px === false ? 3 : isUnder768px === false ? 2 : 1
						}
						pagination={{ clickable: true }}
						onSlideChange={() => console.log("slide change")}
						onSwiper={(swiper) => console.log(swiper)}
					>
						<SwiperSlide>
							<BlockDiv>
								<Box className="relative flex justify-center">
									<Circle className="absolute">
										<CircleText className="text-mediumColor">54,90</CircleText>
									</Circle>
									<img src={Tunczyk} alt="Łosoś" />
								</Box>
								<div className="flex flex-col items-center justify-center mt-6">
									<Title className="text-mediumColor text-center">
										{t("home.card.part1.title")}
									</Title>
									<Desc className="text-mediumColor text-center">
										{t("home.card.part1.desc")}
									</Desc>
								</div>
							</BlockDiv>
						</SwiperSlide>
						<SwiperSlide>
							<BlockDiv>
								<Box className="relative flex justify-center">
									<Circle className="absolute">
										<CircleText className="text-mediumColor">49,90</CircleText>
									</Circle>
									<img src={Warkocz} alt="Warkocz" />
								</Box>
								<div className="flex flex-col items-center justify-center mt-6">
									<Title className="text-mediumColor text-center">
										{t("home.card.part2.title")}
									</Title>
									<Desc className="text-mediumColor text-center">
										{t("home.card.part2.desc")}
									</Desc>
								</div>
							</BlockDiv>
						</SwiperSlide>
						<SwiperSlide>
							<BlockDiv>
								<Box className="relative flex justify-center">
									<Circle className="absolute">
										<CircleText className="text-mediumColor">38,90</CircleText>
									</Circle>
									<img src={Pasta} alt="Pasta" />
								</Box>
								<div className="flex flex-col items-center justify-center mt-6">
									<Title className="text-mediumColor text-center">
										{t("home.card.part3.title")}
									</Title>
									<Desc className="text-mediumColor text-center">
										{t("home.card.part3.desc")}
									</Desc>
								</div>
							</BlockDiv>
						</SwiperSlide>
					</Swiper>
				</ContentDiv>
			</div>
		</MainDiv>
	);
}

export default Card;
