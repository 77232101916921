import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import Footer from "./components/Footer/Footer";
import CheckForUpdate from "./components/Misc/CheckForUpdate";
import ScrollToTop from "./components/Misc/ScrollToTop";
import Updater from "./components/Misc/Updater";
import TobBar from "./components/TopBar/TobBar";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import Online from "./pages/Online";

function App() {
	const { t, i18n } = useTranslation();
	const isUnder1280px = useMediaQuery({ query: `(max-width: 1280px)` });
	const isUnder1024px = useMediaQuery({ query: `(max-width: 1024px)` });
	const isUnder768px = useMediaQuery({ query: `(max-width: 768px)` });
	const isUnder640px = useMediaQuery({ query: `(max-width: 640px)` });

	const changeLanguage = (language) => {
		i18n.changeLanguage(language);
	};
	const test = 0;

	return (
		<div>
			<BrowserRouter>
				<TobBar
					t={t}
					changeLanguage={changeLanguage}
					isUnder1024px={isUnder1024px}
					isUnder640px={isUnder640px}
				/>
				<CheckForUpdate />
				<Updater />
				<ScrollToTop />
				<Routes>
					<Route
						path="/"
						element={
							<Home
								t={t}
								isUnder1280px={isUnder1280px}
								isUnder768px={isUnder768px}
								isUnder640px={isUnder640px}
							/>
						}
					/>
					<Route
						path="/menu"
						element={
							<Menu
								t={t}
								isUnder1280px={isUnder1280px}
								isUnder768px={isUnder768px}
								isUnder640px={isUnder640px}
							/>
						}
					/>
					<Route
						path="/online"
						element={
							<Online
								t={t}
								isUnder1280px={isUnder1280px}
								isUnder768px={isUnder768px}
								isUnder640px={isUnder640px}
							/>
						}
					/>
					<Route path="/*" element={<Navigate replace to="/" />} />
				</Routes>
				<Footer t={t} isUnder640px={isUnder640px} />
			</BrowserRouter>
		</div>
	);
}

export default App;
