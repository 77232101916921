import React from "react";
import LogoImg from "../img/logo.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Scroll from "react-scroll";

const Img = styled.img`
	@media (max-width: 640px) {
		width: 90%;
	}
`;

function Logo() {
	var scroll = Scroll.animateScroll;

	return (
		<div>
			<Link to="/" onClick={() => scroll.scrollToTop()}>
				<Img src={LogoImg} alt="La Gondola" />
			</Link>
		</div>
	);
}

export default Logo;
