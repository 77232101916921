import { MenuBook, RestaurantMenu } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Img1 from "../../img/whyWe1.jpg";
import Img2 from "../../img/whyWe2.jpg";
import Img3 from "../../img/whyWe3.jpg";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

const MainDiv = styled.div`
	margin-top: 70px;
`;

const ContentDiv = styled(motion.div)`
	margin-top: 70px;

	@media (max-width: 1024px) {
		margin-top: 40px;
	}
`;

const Blob = styled.div`
	background-color: #f0f0f0;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	z-index: -1;
	border-radius: 43% 57% 57% 43% / 53% 29% 71% 47%;

	@media (max-width: 640px) {
		border-radius: 30px;
	}
`;

const Title = styled.span`
	font-size: 50px;
	font-weight: 650;

	@media (max-width: 1280px) {
		font-size: 35px;
	}
`;

const Desc = styled.span`
	font-size: 25px;
	font-weight: 200;

	@media (max-width: 1280px) {
		font-size: 20px;
	}
`;

const Img = styled.img`
	border-radius: 50px;
	padding: 0px;

	@media (max-width: 640px) {
		border-radius: 30px;
	}
`;

const TextDiv = styled.div`
	flex: 3;
	max-width: 720px;

	@media (max-width: 640px) {
		padding: 20px;
	}
`;
const ImgDiv = styled.div`
	flex: 2;
	display: flex;
	justify-content: flex-end;
`;

function WhyWe({ t }) {
	const [ref1, inView1] = useInView({
		threshold: 0.1,
	});
	const [ref2, inView2] = useInView({
		threshold: 0.1,
	});
	const [ref3, inView3] = useInView({
		threshold: 0.1,
	});
	const [show1, setShow1] = useState(false);
	const [show2, setShow2] = useState(false);
	const [show3, setShow3] = useState(false);
	const animation1 = useAnimation();
	const animation2 = useAnimation();
	const animation3 = useAnimation();

	useEffect(() => {
		if (show1 === false) {
			if (inView1) {
				animation1.start({
					x: 0,
					transition: {
						type: "spring",
						duration: 1.5,
						bounce: 0.2,
						delay: 0.2,
					},
				});
				setShow1(true);
			}
			if (!inView1) {
				animation1.start({ x: "-100vw" });
			}
		}
	}, [inView1]);

	useEffect(() => {
		if (show2 === false) {
			if (inView2) {
				animation2.start({
					x: 0,
					transition: {
						type: "spring",
						duration: 1.5,
						bounce: 0.2,
						delay: 0.2,
					},
				});
				setShow2(true);
			}
			if (!inView2) {
				animation2.start({ x: "100vw" });
			}
		}
	}, [inView2]);

	useEffect(() => {
		if (show3 === false) {
			if (inView3) {
				animation3.start({
					x: 0,
					transition: {
						type: "spring",
						duration: 1.5,
						bounce: 0.2,
						delay: 0.2,
					},
				});
				setShow3(true);
			}
			if (!inView3) {
				animation3.start({ x: "-100vw" });
			}
		}
	}, [inView3]);

	return (
		<MainDiv className="px-4">
			<span className="bigText">{t("home.whyWe.title")}</span>
			<div ref={ref1}>
				<ContentDiv
					animate={animation1}
					className="flex justify-between items-center relative lg:flex-row flex-col"
				>
					<Blob />
					<TextDiv className="flex flex-col justify-center mr-12 order-2 lg:order-1">
						<Title className="text-mediumColor">
							{t("home.whyWe.part1.title")}
						</Title>
						<Desc className="text-mediumColor">
							{t("home.whyWe.part1.desc")}
						</Desc>
						<div>
							<Link to="/online">
								<button className="filledButton mt-12">
									<div className="flex items-center gap-3">
										<RestaurantMenu
											style={{ fontSize: "24px", color: "white" }}
										/>
										<span className="filledButtonText">
											{t("home.whyWe.part1.button")}
										</span>
									</div>
								</button>
							</Link>
						</div>
					</TextDiv>
					<ImgDiv className="order-1 lg:order-2 sm:mb-12 mb-6 lg:mb-0">
						<Img src={Img1} alt="Dlaczego my1" />
					</ImgDiv>
				</ContentDiv>
			</div>
			<div ref={ref2}>
				<ContentDiv
					animate={animation2}
					style={{ marginTop: "100px" }}
					className="flex justify-between items-center relative lg:flex-row flex-col"
				>
					<Blob />
					<TextDiv className="flex flex-col justify-center lg:items-end items-start lg:ml-12 ml-0 order-2">
						<Title className="text-mediumColor lg:text-end text-left">
							{t("home.whyWe.part2.title")}
						</Title>
						<Desc className="text-mediumColor lg:text-end text-left">
							{t("home.whyWe.part2.desc")}
						</Desc>
						<div>
							<Link to="/menu">
								<button className="whiteButton mt-12">
									<div className="flex items-center gap-3">
										<MenuBook style={{ fontSize: "24px" }} />
										<span className="whiteButtonText">
											{t("home.whyWe.part2.button")}
										</span>
									</div>
								</button>
							</Link>
						</div>
					</TextDiv>
					<ImgDiv
						style={{ justifyContent: "flex-start" }}
						className="order-1 sm:mb-12 mb-6 lg:mb-0"
					>
						<Img src={Img2} alt="Dlaczego my2" />
					</ImgDiv>
				</ContentDiv>
			</div>
			<div ref={ref3}>
				<ContentDiv
					animate={animation3}
					style={{ marginTop: "100px" }}
					className="flex justify-between items-center relative lg:flex-row flex-col"
				>
					<Blob />
					<TextDiv className="flex flex-col justify-center mr-12 order-2 lg:order-1">
						<Title className="text-mediumColor">
							{t("home.whyWe.part3.title")}
						</Title>
						<Desc className="text-mediumColor">
							{t("home.whyWe.part3.desc")}
						</Desc>
						<div>
							<Link to="/menu">
								<button className="whiteButton mt-12">
									<div className="flex items-center gap-3">
										<MenuBook style={{ fontSize: "24px" }} />
										<span className="whiteButtonText">
											{t("home.whyWe.part3.button")}
										</span>
									</div>
								</button>
							</Link>
						</div>
					</TextDiv>
					<ImgDiv className="order-1 lg:order-2 sm:mb-12 mb-6 lg:mb-0">
						<Img src={Img3} alt="Dlaczego my3" />
					</ImgDiv>
				</ContentDiv>
			</div>
		</MainDiv>
	);
}

export default WhyWe;
