import React from "react";
import Telefon from "../SVG/TopBar/Telefon.svg";
import Instagram from "../SVG/TopBar/Instagram.svg";
import Facebook from "../SVG/TopBar/Facebook.svg";

function Social({ t }) {
	return (
		<div className="flex items-center gap-5">
			<a href={t("link.facebook")} target="_blank" rel="noopener noreferrer">
				<img
					width={"36px"}
					className="cursor-pointer"
					src={Facebook}
					alt="Facebook"
				/>
			</a>
			<a href={t("link.instagram")} target="_blank" rel="noopener noreferrer">
				<img
					width={"36px"}
					className="cursor-pointer"
					src={Instagram}
					alt="Instagram"
				/>
			</a>
			<a href={t("link.telefon")} className="flex items-center gap-5">
				<img
					width={"36px"}
					className="cursor-pointer"
					src={Telefon}
					alt="Telefon"
				/>
				<span className="phoneTopBarSpan text-orange">(68) 320 20 12</span>
			</a>
		</div>
	);
}

export default Social;
