import React from "react";
import Angielski from "../SVG/Flags/Angielski.svg";
import Niemcy from "../SVG/Flags/Niemcy.svg";
import Polska from "../SVG/Flags/Polska.svg";

function Flags({ changeLanguage }) {
	return (
		<div className="flex gap-4">
			<img
				onClick={() => changeLanguage("pl")}
				className="cursor-pointer"
				src={Polska}
				alt="Polska"
			/>
			<img
				onClick={() => changeLanguage("de")}
				className="cursor-pointer"
				src={Niemcy}
				alt="Niemcy"
			/>
			<img
				onClick={() => changeLanguage("en")}
				className="cursor-pointer"
				src={Angielski}
				alt="Angielski"
			/>
		</div>
	);
}

export default Flags;
