import { onServiceWorkerUpdate } from "@3m1/service-worker-updater";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Suspense fallback={<div></div>}>
		<App />
	</Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.register({
	onUpdate: onServiceWorkerUpdate,
});
