import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Scroll from "react-scroll";

function Routes({ t, setIsOpen }) {
	const scroll = Scroll.scroller;

	return (
		<>
			<Link
				to="/"
				onClick={() =>
					scroll.scrollTo("aboutUs", {
						duration: 500,
						delay: 100,
						smooth: true,
						offset: -230, // Scrolls to element + 50 pixels down the page
					}) && setIsOpen((isOpen) => !isOpen)
				}
			>
				<li className="topBarFonts text-gray">{t("topBar.navBar.aboutUs")}</li>
			</Link>
			<HashLink to="online">
				<li className="topBarFonts text-orange">{t("topBar.navBar.online")}</li>
			</HashLink>
			<HashLink to="menu">
				<li className="topBarFonts text-gray">{t("topBar.navBar.menu")}</li>
			</HashLink>
			<Link
				to="/"
				onClick={() =>
					scroll.scrollTo("promotion", {
						duration: 500,
						delay: 100,
						smooth: true,
						offset: -180, // Scrolls to element + 50 pixels down the page
					}) && setIsOpen((isOpen) => !isOpen)
				}
			>
				<li className="topBarFonts text-gray">
					{t("topBar.navBar.discounts")}
				</li>
			</Link>
			<Link
				to="/"
				onClick={() =>
					scroll.scrollTo("contact", {
						duration: 500,
						delay: 100,
						smooth: true,
						offset: -180, // Scrolls to element + 50 pixels down the page
					}) && setIsOpen((isOpen) => !isOpen)
				}
			>
				<li className="topBarFonts text-gray">{t("topBar.navBar.contact")}</li>
			</Link>
		</>
	);
}

export default Routes;
