import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import Banner from "../components/Home/Banner/Banner";
import Card from "../components/Home/Card/Card";
import Discount from "../components/Home/Discount/Discount";
import InfoBlock from "../components/Home/InfoBlock/InfoBlock";
import Instagram from "../components/Home/Instagram/Instagram";
import Map from "../components/Home/Map/Map";
import Menu from "../components/Home/Menu/Menu";
import Numbers from "../components/Home/Numbers/Numbers";
import Phone from "../components/Home/Phone/Phone";
import WhyWe from "../components/Home/WhyWe/WhyWe";

const MainDiv = styled.div`
	margin-top: 128px;
	overflow: hidden;

	@media (max-width: 640px) {
		margin-top: 100px;
	}
`;

function Home({ t, isUnder1280px, isUnder768px, isUnder640px }) {
	return (
		<MainDiv>
			<Helmet>
				<title>
					Restauracja La Gondola – ul. Kościelna 1, Zielona Góra. Tel.: (68) 320
					20 12 | pn-czw 11-22, pt-nd 11-23
				</title>
				<meta
					name="description"
					content="Już od 2016 roku specjalizujemy się w wypiekaniu pysznej chrupiącej pizzy. Jesteśmy doskonałym miejscem, żeby wybrać się z rodziną na obiad, spotkać się ze znajomymi czy zorganizować imprezę okolicznościową np. chrzciny lub komunię. Na miejscu czekać na Ciebie będzie przemiła i pomocna obsługa. Pizza, sałatki, makarony, dania obiadowe dostawa Zielona Góra"
				/>
				<link rel="canonical" href="/" />
			</Helmet>
			<Banner t={t} isUnder640px={isUnder640px} />
			<div className="container mx-auto">
				<Phone t={t} />
				<Numbers t={t} />
				<InfoBlock t={t} />
				<Discount t={t} isUnder768px={isUnder768px} />
				<WhyWe t={t} />
				<Card t={t} isUnder1280px={isUnder1280px} isUnder768px={isUnder768px} />
				<Menu t={t} />
				<Instagram t={t} />
				<Map t={t} />
			</div>
		</MainDiv>
	);
}

export default Home;
