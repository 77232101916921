import React, { useEffect, useMemo, useState } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const MainDiv = styled.div`
	margin-top: 70px;
`;

const ContentDiv = styled(motion.div)`
	margin-top: 70px;

	@media (max-width: 1024px) {
		margin-top: 40px;
	}
`;

function Map({ t }) {
	const { ref, inView } = useInView({
		threshold: 0.1,
	});
	const [show, setShow] = useState(false);
	const animation = useAnimation();
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: "AIzaSyB9wWchyEw39VXGcS34A1Tyyd5QxXQnUKQ",
	});

	const center = useMemo(
		() => ({ lat: 51.93834113662814, lng: 15.505694142647068 }),
		[]
	);

	useEffect(() => {
		if (show === false) {
			if (inView) {
				animation.start({
					opacity: 1,
					transition: {
						type: "spring",
						duration: 1.5,
						bounce: 0.2,
						delay: 0.2,
					},
				});
				setShow(true);
			}
			if (!inView) {
				animation.start({ opacity: 0 });
			}
		}
	}, [inView]);

	return (
		<MainDiv className="px-4 mb-12">
			<div>
				<span className="bigText">{t("home.map.title")}</span>
			</div>
			<div ref={ref}>
				<ContentDiv animate={animation}>
					{!isLoaded ? (
						<></>
					) : (
						<GoogleMap
							zoom={18}
							center={{ lat: 51.93834113662814, lng: 15.505694142647068 }}
							mapContainerClassName="map-container"
						>
							<MarkerF position={center} />
						</GoogleMap>
					)}
				</ContentDiv>
			</div>
		</MainDiv>
	);
}

export default Map;
