import axios from "axios";
import { motion, useAnimation } from "framer-motion";
import { changeLanguage } from "i18next";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import ReactPlayer from "react-player";
import styled from "styled-components";

const MainDiv = styled.div`
	margin-top: 70px;
`;

const ContentDiv = styled(motion.div)`
	margin-top: 70px;

	@media (max-width: 1280px) {
		margin-top: 40px;
	}
`;

const Img = styled.img`
	width: 429px;
	height: 429px;
	object-fit: cover;

	@media (max-width: 1400px) {
		width: 389px;
		height: 389px;
	}
	@media (max-width: 1280px) {
		width: 302px;
		height: 302px;
	}
`;
const Video = styled(ReactPlayer)`
	width: 429px;
	height: 429px;
	object-fit: cover;

	@media (max-width: 1400px) {
		width: 389px;
		height: 389px;
	}
	@media (max-width: 1280px) {
		width: 302px;
		height: 302px;
	}
`;

const Logo = styled.img`
	width: 85px;
	height: 85px;
	object-fit: cover;
	border-radius: 50px;
	@media (max-width: 1280px) {
		width: 75px;
		height: 75px;
	}
`;
const LogoTitle = styled.span`
	font-size: 25px;
	font-weight: 200;

	@media (max-width: 1280px) {
		font-size: 20px;
	}
`;
const Desc = styled.span`
	font-size: 30px;
	font-weight: 700;

	@media (max-width: 1280px) {
		font-size: 20px;
	}
`;

function Instagram({ t }) {
	const [photos, setPhotos] = useState(null);
	const [loading, setLoading] = useState(true);
	const { ref, inView } = useInView({
		threshold: 0.1,
	});
	const [show, setShow] = useState(false);
	const animation = useAnimation();
	const [play, setPlay] = useState(false);

	const handleGetInstagram = async () => {
		try {
			await axios({
				url: `https://api.futureup.pl/api/v1/system/insta/get?companyId=63135ff6ac0a93368c130479`,
				method: "GET",
			}).then((res) => {
				setPhotos(res.data);
				setLoading(false);
			});
		} catch (err) {}
	};

	useEffect(() => {
		if (show === false) {
			if (inView) {
				animation.start({
					opacity: 1,
					transition: {
						type: "spring",
						duration: 1.5,
						bounce: 0.2,
						delay: 0.2,
					},
				});
				setShow(true);
			}
			if (!inView) {
				animation.start({ opacity: 0 });
			}
		}
	}, [inView, loading]);

	useEffect(() => {
		handleGetInstagram();
	}, []);

	return (
		<div className="px-4">
			{loading === false && photos !== null && (
				<>
					<MainDiv>
						<span className="bigText">{t("home.instagram.title")}</span>
					</MainDiv>
					<div ref={ref}>
						<ContentDiv animate={animation}>
							<div>
								<Desc>{t("home.instagram.desc")}</Desc>
							</div>
							<div className="flex items-center gap-6 lg:ml-3 ml-0 mt-6">
								<a
									className="flex items-center gap-6 lg:ml-3 ml-0 mt-6"
									href={photos.link}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Logo src={photos.logo} alt={photos.name} />
									<LogoTitle className="text-mediumColor">
										{photos.username}
									</LogoTitle>
								</a>
							</div>
							<div className="flex flex-wrap gap-10 justify-center mt-6">
								{photos.photosList?.map((item) =>
									item.media_type === "IMAGE" ||
									item.media_type === "CAROUSEL_ALBUM" ? (
										<a
											href={item.permalink}
											target="_blank"
											rel="noopener noreferrer"
											className="post-item"
											key={item.id}
										>
											<Img
												src={item.media_url}
												className="post-image"
												alt={item.caption ? item.caption.substring(0, 40) : ""}
											/>
											{/*  */}
											<div className="post-item-info">
												<ul>
													<li className="post-item-likes">
														<span role="img" aria-label="heart">
															<svg
																width="2em"
																height="2em"
																viewBox="0 0 24 24"
																fill="white"
																style={{
																	fontSize: "14px",
																	lineHeight: "1.45",
																}}
															>
																<path d="M12 4.435C10.011-.964 0-.162 0 8.003 0 12.071 3.06 17.484 12 23c8.94-5.516 12-10.929 12-14.997C24-.115 14-.996 12 4.435z"></path>
															</svg>
														</span>{" "}
														<span className="font-bold">
															{item.like_count !== null
																? item.like_count.toLocaleString()
																: 0}
														</span>
													</li>
													<li className="post-item-comments">
														<span role="img" aria-label="speech-balloon">
															<svg
																width="2em"
																height="2em"
																viewBox="0 0 24 24"
																fill="white"
																style={{
																	fontSize: "14px",
																	lineHeight: "1.45",
																}}
															>
																<path d="M24 9.874C24 4.42 18.627 0 12 0S0 4.42 0 9.874c0 4.512 3.678 8.317 8.701 9.496L12 24l3.299-4.63C20.322 18.19 24 14.385 24 9.874z"></path>
															</svg>
														</span>{" "}
														<span className="font-bold">
															{item.comments_count !== null
																? item.comments_count.toLocaleString()
																: 0}
														</span>
													</li>
												</ul>
											</div>
										</a>
									) : (
										<a
											href={item.permalink}
											target="_blank"
											rel="noopener noreferrer"
											className="post-item"
											key={item.id}
										>
											<Video
												className="post-video"
												volume={0}
												url={item.media_url}
												width="100%"
												height="100%"
												playing={play}
											/>
											<div
												className="post-item-info"
												onMouseEnter={() => setPlay(true)}
												onMouseLeave={() => setPlay(false)}
											>
												<ul>
													<li className="post-item-likes">
														<span role="img" aria-label="heart">
															<svg
																width="2em"
																height="2em"
																viewBox="0 0 24 24"
																fill="white"
																style={{
																	fontSize: "14px",
																	lineHeight: "1.45",
																}}
															>
																<path d="M12 4.435C10.011-.964 0-.162 0 8.003 0 12.071 3.06 17.484 12 23c8.94-5.516 12-10.929 12-14.997C24-.115 14-.996 12 4.435z"></path>
															</svg>
														</span>{" "}
														<span className="font-bold">
															{item.like_count !== null
																? item.like_count.toLocaleString()
																: 0}
														</span>
													</li>
													<li className="post-item-comments">
														<span role="img" aria-label="speech-balloon">
															<svg
																width="2em"
																height="2em"
																viewBox="0 0 24 24"
																fill="white"
																style={{
																	fontSize: "14px",
																	lineHeight: "1.45",
																}}
															>
																<path d="M24 9.874C24 4.42 18.627 0 12 0S0 4.42 0 9.874c0 4.512 3.678 8.317 8.701 9.496L12 24l3.299-4.63C20.322 18.19 24 14.385 24 9.874z"></path>
															</svg>
														</span>{" "}
														<span className="font-bold">
															{item.comments_count !== null
																? item.comments_count.toLocaleString()
																: 0}
														</span>
													</li>
												</ul>
											</div>
										</a>
									)
								)}
							</div>
						</ContentDiv>
					</div>
				</>
			)}
		</div>
	);
}

export default Instagram;
