import React, { useEffect, useState } from "react";
// import Img from "../../img/promocja.jpg";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
// import Image1 from "../../img/promocja.png";
import Image1 from "../../img/promocja3.jpeg";
// import Image2 from "../../img/promocja2-2.jpg";

const MainDiv = styled(motion.div)`
	margin-top: 70px;
	scroll-margin-top: 180px;

	@media (max-width: 1024px) {
		scroll-margin-top: 130px;
	}
`;

const ImgDiv = styled.div`
	margin-top: 70px;
	display: flex;
	/* justify-content: center; */
	gap: 32px;
	/* justify-content: space-between; */

	@media (max-width: 1024px) {
		flex-direction: column;
		align-items: center;
	}
`;

const Image = styled.img`
	border-radius: 30px;
	max-width: 700px;
	width: 100%;
	/* max-width: 650px; */
	@media (max-width: 1400px) {
		/* max-width: 580px; */
	}
	@media (max-width: 1280px) {
		/* max-width: 480px; */
	}
	@media (max-width: 1024px) {
		width: 100%;
	}
`;

const Desc = styled.span`
	font-size: 15px;
	font-weight: 400;
`;

function Discount({ t, isUnder768px }) {
	const { ref, inView } = useInView({
		threshold: 0.1,
	});
	const [show, setShow] = useState(false);
	const animation = useAnimation();

	useEffect(() => {
		if (show === false) {
			if (inView) {
				animation.start({
					opacity: 1,
					transition: {
						type: "spring",
						duration: 1.5,
						bounce: 0.2,
						delay: 0.2,
					},
				});
				setShow(true);
			}
			if (!inView) {
				animation.start({ opacity: 0 });
			}
		}
	}, [inView]);

	return (
		<div ref={ref}>
			<MainDiv id="promotion" animate={animation} className="px-4">
				<span className="bigText">{t("home.discount.title")}</span>
				<ImgDiv>
					<Image src={Image1} alt="Promocja1" />
					{/* <Image src={Image2} alt="Promocja2" /> */}
				</ImgDiv>
				<div className="ml-8 mt-4">
					<Desc>{t("home.discount.desc")}</Desc>
				</div>
			</MainDiv>
		</div>
	);
}

export default Discount;
