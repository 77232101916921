import React from "react";
import styled from "styled-components";
import Girl from "../../img/girl.png";

const MainDiv = styled.div`
	background-color: #fdf9e7;
	border-radius: 75px;
	display: flex;
	padding: 56px 45px;
	scroll-margin-top: 228px;

	@media (max-width: 1024px) {
		flex-direction: column;
		align-items: center;
		padding-bottom: 0px;
		padding-top: 45px;
		scroll-margin-top: 140px;
	}
`;
const Title = styled.span`
	font-size: 30px;
	font-weight: 650;
	max-width: 580px;
	margin-bottom: 20px;

	@media (max-width: 1280px) {
		font-size: 25px;
		max-width: 510px;
	}

	@media (max-width: 1024px) {
		max-width: 100%;
	}
`;
const Desc = styled.span`
	font-size: 25px;
	font-weight: 200;
	max-width: 530px;

	@media (max-width: 1280px) {
		font-size: 20px;
		max-width: 480px;
	}

	@media (max-width: 1024px) {
		max-width: 100%;
	}
`;
const ImgDiv = styled.div`
	right: 20px;
	top: 0;

	transform: translateY(-65px);

	@media (max-width: 1400px) {
		height: 105%;
		transform: translateY(-24px);
		right: 00px;
	}

	@media (max-width: 1280px) {
		height: 100%;
		transform: translateY(0px);
	}

	@media (max-width: 1024px) {
		width: 80%;
		margin-top: 50px;
	}

	@media (max-width: 520px) {
		width: 120%;
		margin-top: 30px;
	}
`;

function InfoBlock({ t }) {
	return (
		<div className="px-4">
			<MainDiv id="aboutUs" className="relative">
				<div className="flex flex-col">
					<Title className="text-mediumColor">
						{t("home.infoBlock.title")}
					</Title>
					<Desc className="text-mediumColor">{t("home.infoBlock.desc")}</Desc>
				</div>
				<ImgDiv className="flex lg:absolute">
					<img src={Girl} alt="Dziewczyna trzymająca pizze" />
				</ImgDiv>
			</MainDiv>
		</div>
	);
}

export default InfoBlock;
