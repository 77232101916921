import React, { useEffect, useRef, useState } from "react";
import Img from "../../img/phone.png";
import GooglePlay from "../../img/google_play.png";
import AppStore from "../../SVG/Footer/app_store.svg";
import styled from "styled-components";

const MainDiv = styled.div`
	margin-bottom: 180px;
	margin-top: 100px;

	@media (max-width: 1024px) {
		margin-bottom: 40px;
		margin-top: 50px;
	}
`;

const BG = styled.div`
	background-color: #fdf9e7;
	border-radius: 75px;
	max-width: 1020px;
	display: flex;
	margin-top: 45px;

	@media (max-width: 1024px) {
		justify-content: center;
		align-items: center;
		max-width: 331px;
		margin: auto;
	}
`;
const Block = styled.div`
	display: flex;
	width: 100%;
	/* padding: 30px 30px; */
	scroll-margin-top: 228px;

	@media (max-width: 1024px) {
		flex-direction: column;
		align-items: center;
		scroll-margin-top: 140px;
		max-width: 331px;
	}
`;
const TitleDiv = styled.div`
	@media (max-width: 1024px) {
		margin-bottom: 25px;
	}
`;
const TextDiv = styled.div`
	margin-left: 381px;
	padding-top: 30px;
	padding-bottom: 30px;

	@media (max-width: 1024px) {
		margin-left: 0px;
		padding-top: 15px;
		padding-bottom: 30px;
		padding-left: 30px;
		padding-right: 30px;
	}
`;
const Title = styled.span`
	font-size: 25px;
	font-weight: 650;
	max-width: 600px;

	@media (max-width: 1280px) {
		max-width: 510px;
	}

	@media (max-width: 1024px) {
		max-width: 100%;
		font-size: 20px;
	}
`;
const Desc = styled.span`
	font-size: 25px;
	font-weight: 200;
	max-width: 600px;

	@media (max-width: 1280px) {
		font-size: 20px;
		max-width: 480px;
	}

	@media (max-width: 1024px) {
		max-width: 100%;
		font-size: 20px;
	}
`;
const AppsDiv = styled.div`
	margin-left: 381px;
	margin-top: 20px;
	display: flex;
	align-items: center;
	gap: 50px;

	@media (max-width: 1024px) {
		margin-left: 0px;
		flex-direction: column;
		align-items: center;
		gap: 25px;
		margin-top: 30px;
	}
`;
const Image = styled.img`
	position: absolute;

	@media (max-width: 1024px) {
		position: static;
	}
`;

function Phone({ t }) {
	const ref = useRef(null);
	const [imageWidth, setImageWidth] = useState(
		ref.current ? ref.current.offsetWidth : 0
	);

	console.log(imageWidth);

	useEffect(() => {
		setImageWidth(ref.current ? ref.current.offsetWidth : 0);
	}, [ref.current]);

	return (
		<MainDiv className="px-4">
			<TitleDiv>
				<span className="bigText">{t("home.phone.title")}</span>
			</TitleDiv>
			<BG>
				<Block>
					<Image ref={ref} src={Img} alt="aplikacja" />
					<TextDiv imageWidth={imageWidth} className="flex flex-col">
						<Title>{t("home.phone.part1")}</Title>
						<Desc>{t("home.phone.part2")}</Desc>
					</TextDiv>
				</Block>
			</BG>
			<AppsDiv imageWidth={imageWidth}>
				<div>
					<a href={t("link.ios")} target="_blank" rel="noopener noreferrer">
						<img
							style={{ width: "200px", cursor: "pointer" }}
							src={AppStore}
							alt="AppStore"
						/>
					</a>
				</div>
				<div>
					<a href={t("link.android")} target="_blank" rel="noopener noreferrer">
						<img
							style={{ width: "200px", cursor: "pointer" }}
							src={GooglePlay}
							alt="Google Play"
						/>
					</a>
				</div>
			</AppsDiv>
		</MainDiv>
	);
}

export default Phone;
