import React from "react";
import Routes from "../../routes/Routes";

function Nav({ t, setIsOpen }) {
	return (
		<ul className="flex xl:gap-12 gap-6">
			<Routes setIsOpen={setIsOpen} t={t} />
		</ul>
	);
}

export default Nav;
