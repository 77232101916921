import { MenuBook, RestaurantMenu } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

const MainDiv = styled.div`
	margin-top: 70px;
`;

const ContentDiv = styled(motion.div)`
	margin-top: 160px;
	display: flex;
	justify-content: space-between;
	gap: 100px;
	/* max-width: 450px; */
	@media (max-width: 1280px) {
		margin-top: 100px;
	}
	@media (max-width: 1024px) {
		flex-direction: column;
	}
	@media (max-width: 640px) {
		margin-top: 60px;
		gap: 50px;
	}
`;

const RowDiv = styled.div`
	height: 120px;

	@media (max-width: 640px) {
		height: auto;
		background-image: repeating-linear-gradient(
				0deg,
				#707070,
				#707070 10px,
				transparent 10px,
				transparent 14px,
				#707070 14px
			),
			repeating-linear-gradient(
				90deg,
				#707070,
				#707070 10px,
				transparent 10px,
				transparent 14px,
				#707070 14px
			),
			repeating-linear-gradient(
				180deg,
				#707070,
				#707070 10px,
				transparent 10px,
				transparent 14px,
				#707070 14px
			),
			repeating-linear-gradient(
				270deg,
				#707070,
				#707070 10px,
				transparent 10px,
				transparent 14px,
				#707070 14px
			);
		background-size: 1px 0%, 0% 1px, 1px 0%, 100% 1px;
		background-position: 0 0, 0 0, 100% 0, 0 100%;
		background-repeat: no-repeat;
		padding-bottom: 10px;
	}
`;

const Title = styled.span`
	font-size: 30px;
	font-weight: 800;

	@media (max-width: 1280px) {
		font-size: 25px;
	}
`;
const Desc = styled.span`
	font-size: 25px;
	font-weight: 200;

	@media (max-width: 1280px) {
		font-size: 20px;
	}
`;

const PriceDiv = styled.div`
	flex: 1;

	@media (min-width: 640px) {
		background-image: repeating-linear-gradient(
				0deg,
				#707070,
				#707070 10px,
				transparent 10px,
				transparent 14px,
				#707070 14px
			),
			repeating-linear-gradient(
				90deg,
				#707070,
				#707070 10px,
				transparent 10px,
				transparent 14px,
				#707070 14px
			),
			repeating-linear-gradient(
				180deg,
				#707070,
				#707070 10px,
				transparent 10px,
				transparent 14px,
				#707070 14px
			),
			repeating-linear-gradient(
				270deg,
				#707070,
				#707070 10px,
				transparent 10px,
				transparent 14px,
				#707070 14px
			);
		background-size: 1px 0%, 0% 1px, 1px 0%, 100% 1px;
		background-position: 0 0, 0 0, 100% 0, 0 100%;
		background-repeat: no-repeat;
	}
`;
const Price = styled.span`
	font-size: 25px;
	font-weight: 600;

	@media (max-width: 1280px) {
		font-size: 20px;
	}
`;
const SizeDiv = styled.div`
	position: absolute;
	top: -80px;
	right: 0;

	@media (max-width: 1280px) {
		top: -60px;
	}

	@media (max-width: 640px) {
		display: none;
	}
`;
const SizeTitle = styled.span`
	font-size: 20px;
	font-weight: 700;

	@media (max-width: 1280px) {
		font-size: 15px;
	}
`;
const SizeDesc = styled.span`
	font-size: 20px;
	font-weight: 200;

	@media (max-width: 1280px) {
		font-size: 15px;
	}
`;

function Menu({ t }) {
	const { ref, inView } = useInView({
		threshold: 0.1,
	});
	const [show, setShow] = useState(false);
	const animation = useAnimation();

	useEffect(() => {
		if (show === false) {
			if (inView) {
				animation.start({
					opacity: 1,
					transition: {
						type: "spring",
						duration: 1.5,
						bounce: 0.2,
						delay: 0.2,
					},
				});
				setShow(true);
			}
			if (!inView) {
				animation.start({ opacity: 0 });
			}
		}
	}, [inView]);

	return (
		<MainDiv className="px-4">
			<span className="bigText">{t("home.menu.title")}</span>
			<div ref={ref}>
				<ContentDiv animate={animation} className="sm:px-10 px-0">
					<div className="flex flex-col sm:gap-8 gap-4 flex-1">
						<RowDiv>
							<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start relative">
								<SizeDiv className="flex xl:gap-6 gap-7">
									<div className="flex flex-col items-center">
										<SizeTitle>{t("home.menu.size1")}</SizeTitle>
										<SizeDesc>(31cm)</SizeDesc>
									</div>
									<div className="flex flex-col items-center">
										<SizeTitle>{t("home.menu.size2")}</SizeTitle>
										<SizeDesc>(50cm)</SizeDesc>
									</div>
								</SizeDiv>
								<div>
									<Title className="text-mediumColor">
										{t("home.menu.part1.title")}
									</Title>
								</div>
								<PriceDiv>
									<div className="flex gap-8 justify-end items-center">
										<Price className="text-mediumColor">
											{t("home.menu.part1.price1")}
										</Price>
										<Price className="text-mediumColor">
											{t("home.menu.part1.price2")}
										</Price>
									</div>
								</PriceDiv>
							</div>
							<div>
								<Desc className="text-mediumColor">
									{t("home.menu.part1.desc")}
								</Desc>
							</div>
						</RowDiv>
						<RowDiv>
							<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start">
								<div>
									<Title className="text-mediumColor">
										{t("home.menu.part2.title")}
									</Title>
								</div>
								<PriceDiv>
									<div className="flex gap-8 justify-end items-center">
										<Price className="text-mediumColor">
											{t("home.menu.part2.price1")}
										</Price>
										<Price className="text-mediumColor">
											{t("home.menu.part2.price2")}
										</Price>
									</div>
								</PriceDiv>
							</div>
							<div>
								<Desc className="text-mediumColor">
									{t("home.menu.part2.desc")}
								</Desc>
							</div>
						</RowDiv>
						<RowDiv>
							<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start">
								<div>
									<Title className="text-mediumColor">
										{t("home.menu.part3.title")}
									</Title>
								</div>
								<PriceDiv>
									<div className="flex gap-8 justify-end items-center">
										<Price className="text-mediumColor">
											{t("home.menu.part3.price1")}
										</Price>
										<Price className="text-mediumColor">
											{t("home.menu.part3.price2")}
										</Price>
									</div>
								</PriceDiv>
							</div>
							<div>
								<Desc className="text-mediumColor">
									{t("home.menu.part3.desc")}
								</Desc>
							</div>
						</RowDiv>
						<RowDiv>
							<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start">
								<div>
									<Title className="text-mediumColor">
										{t("home.menu.part4.title")}
									</Title>
								</div>
								<PriceDiv>
									<div className="flex gap-8 justify-end items-center">
										<Price className="text-mediumColor">
											{t("home.menu.part4.price1")}
										</Price>
										<Price className="text-mediumColor">
											{t("home.menu.part4.price2")}
										</Price>
									</div>
								</PriceDiv>
							</div>
							<div>
								<Desc className="text-mediumColor">
									{t("home.menu.part4.desc")}
								</Desc>
							</div>
						</RowDiv>
					</div>
					<div className="flex flex-col gap-8 flex-1">
						<RowDiv>
							<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start">
								<div>
									<Title className="text-mediumColor">
										{t("home.menu.part5.title")}
									</Title>
								</div>
								<PriceDiv>
									<div className="flex gap-8 justify-end items-center">
										<Price className="text-mediumColor">
											{t("home.menu.part5.price1")}
										</Price>
										<Price className="text-mediumColor">
											{t("home.menu.part5.price2")}
										</Price>
									</div>
								</PriceDiv>
							</div>
							<div>
								<Desc className="text-mediumColor">
									{t("home.menu.part5.desc")}
								</Desc>
							</div>
						</RowDiv>
						<RowDiv>
							<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start">
								<div>
									<Title className="text-mediumColor">
										{t("home.menu.part6.title")}
									</Title>
								</div>
								<PriceDiv>
									<div className="flex gap-8 justify-end items-center">
										<Price className="text-mediumColor">
											{t("home.menu.part6.price1")}
										</Price>
										<Price className="text-mediumColor">
											{t("home.menu.part6.price2")}
										</Price>
									</div>
								</PriceDiv>
							</div>
							<div>
								<Desc className="text-mediumColor">
									{t("home.menu.part6.desc")}
								</Desc>
							</div>
						</RowDiv>
						<RowDiv>
							<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start">
								<div>
									<Title className="text-mediumColor">
										{t("home.menu.part7.title")}
									</Title>
								</div>
								<PriceDiv>
									<div className="flex gap-8 justify-end items-center">
										<Price className="text-mediumColor">
											{t("home.menu.part7.price1")}
										</Price>
										<Price className="text-mediumColor">
											{t("home.menu.part7.price2")}
										</Price>
									</div>
								</PriceDiv>
							</div>
							<div>
								<Desc className="text-mediumColor">
									{t("home.menu.part7.desc")}
								</Desc>
							</div>
						</RowDiv>
						<RowDiv>
							<div className="flex sm:flex-row flex-col justify-between sm:items-center items-start">
								<div>
									<Title className="text-mediumColor">
										{t("home.menu.part8.title")}
									</Title>
								</div>
								<PriceDiv>
									<div className="flex gap-8 justify-end items-center">
										<Price className="text-mediumColor">
											{t("home.menu.part8.price1")}
										</Price>
										<Price className="text-mediumColor">
											{t("home.menu.part8.price2")}
										</Price>
									</div>
								</PriceDiv>
							</div>
							<div>
								<Desc className="text-mediumColor">
									{t("home.menu.part8.desc")}
								</Desc>
							</div>
						</RowDiv>
					</div>
				</ContentDiv>
			</div>
			<div className="flex justify-center mt-4">
				<Link to="/menu">
					<button className="whiteButton mt-12">
						<div className="flex items-center gap-3">
							<MenuBook style={{ fontSize: "24px" }} />
							<span className="whiteButtonText">{t("home.menu.button")}</span>
						</div>
					</button>
				</Link>
			</div>
		</MainDiv>
	);
}

export default Menu;
