import React from "react";
import styled from "styled-components";

const MainDiv = styled.div`
	margin-bottom: 140px;

	@media (max-width: 1280px) {
		margin-bottom: 80px;
	}

	@media (max-width: 1024px) {
		margin-bottom: 70px;
	}
`;

const NumberSpan = styled.span`
	font-size: 100px;
	font-weight: bold;

	@media (max-width: 1280px) {
		font-size: 80px;
	}
`;

function Numbers({ t }) {
	return (
		<MainDiv className="flex xl:justify-around justify-between lg:flex-row flex-col lg:gap-0 gap-4 px-4 mt-6 lg:mt-12">
			<div className="flex flex-col items-center">
				<NumberSpan className="text-bigTextColor">6</NumberSpan>
				<span className="mediumText">{t("home.numbers.part1")}</span>
			</div>
			<div className="flex flex-col items-center">
				<NumberSpan className="text-bigTextColor">300000+</NumberSpan>
				<span className="mediumText">{t("home.numbers.part2")}</span>
			</div>
			<div className="flex flex-col items-center">
				<NumberSpan className="text-bigTextColor">10000+</NumberSpan>
				<span className="mediumText">{t("home.numbers.part3")}</span>
			</div>
		</MainDiv>
	);
}

export default Numbers;
