import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const MainDiv = styled.div`
	margin-top: 20px;
	overflow: hidden;
	min-height: 100vh;

	@media (max-width: 1024px) {
		margin-top: -220px;
	}
`;

function Online() {
	return (
		<MainDiv>
			<Helmet>
				<title>Restauracja La Gondola – zamów online</title>
				<meta
					name="description"
					content="Już od 2016 roku specjalizujemy się w wypiekaniu pysznej chrupiącej pizzy. Jesteśmy doskonałym miejscem, żeby wybrać się z rodziną na obiad, spotkać się ze znajomymi czy zorganizować imprezę okolicznościową np. chrzciny lub komunię. Na miejscu czekać na Ciebie będzie przemiła i pomocna obsługa. Pizza, sałatki, makarony, dania obiadowe dostawa Zielona Góra"
				/>
				<link rel="canonical" href="/online" />
				<script src="https://cdn.upmenu.com/media/upmenu-widget.js"></script>
			</Helmet>
			<div className="container mx-auto">
				<div id="upmenu-widget"></div>
			</div>
		</MainDiv>
	);
}

export default Online;
